<template>
  <div class="">
    <flickity
      ref="flickity"
      v-if="displayScroll"
      :options="$mq == 'sm' ? flickityOptions : flickityOptionsDesk"
    >
      <vue-load-image
        v-for="img in gallery"
        v-bind:key="img.ID"
        class="carousel-cell mx-1"
      >
        <img
          v-img:gallery.length
          slot="image"
          :class="$mq == 'sm' ? 'w-100' : ''"
          :src="img.url"
          :alt="name"
          height="400"
          width="400"
        />
        <img
          slot="preloader"
          src="@/assets/loader.gif"
          height="400"
          width="400"
        />
        <img slot="error" src="@/assets/loader.gif" height="400" />
      </vue-load-image>
    </flickity>
    <!-- <flickity ref="flickity2" v-if="displayScroll"  class="carousel-thumb " :options="flickityOptions2">
        <img :src="img.url" v-for="img in gallery" width="100" height="100" v-bind:key="img.ID" alt="">
    </flickity> -->
  </div>
</template>

<script>
import Flickity from "vue-flickity";
import VueLoadImage from "vue-load-image";

import "flickity-as-nav-for";
export default {
  name: "ImgScrollProd",
  props: ["gallery", "name"],
  components: {
    Flickity,
    VueLoadImage,
  },
  data() {
    return {
      flickityOptions: {
        pageDots: true,
        prevNextButtons: false,
        fullscreen: true,
        rightToLeft: true,
      },
      flickityOptionsDesk: {
        pageDots: true,
        prevNextButtons: true,
        fullscreen: true,
        rightToLeft: true,
      },
      flickityOptions2: {
        height: 500,
        asNavFor: ".carousel-main",
        freeScroll: false,
        contain: true,
        prevNextButtons: true,
        pageDots: false,
        wrapAround: false,
      },
      displayScroll: true,
    };
  },
  watch: {
    //   gallery(newValue){
    //     if(newValue){
    //         console.log('gallery data passed',newValue)
    //         this.displayScroll=true
    //     }
    //   }
  },
};
</script>

<style scoped>
.carousel-main img {
  width: 100%;
}

.carousel-thumb,
.carousel-main {
  width: 100%;
  margin: 8px;
}

.carousel-thumb img {
  margin: 2px;
}
.carousel.is-fullscreen .carousel-cell {
  height: 100%;
}
.carousel-cell {
  width: 100%;
  height: 400px; /* height of carousel */

  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
