<template>
  <div v-if="Product && Product.images[0].src != 'Empty'" class="ProdCard">
    <div class="product-grid">
      <div class="product-image">
        <div
          class="product-description"
          v-if="Product.short_description"
          v-html="Product.short_description"
        ></div>
        <router-link
          :to="{
            name: 'ProdOne',
            params: { Id: Product.id, ProdName: Product.name },
          }"
          class="image"
          style="min-width: 150"
        >
          <img
            class="img-fluid mx-auto"
            v-lazy="Product.images[0].src"
            :alt="Product.name"
            width="210"
            height="210"
          />
        </router-link>
        <span v-if="Product.on_sale" class="discount-lab"
          >% {{ discountPrice(Product) }}</span
        >
      </div>
      <div class="product-data">
        <h3 class="title">
          <router-link
            :to="{
              name: 'ProdOne',
              params: { Id: Product.id },
            }"
            >{{ Product.name }}
          </router-link>
        </h3>
        <div class="price">
          <span v-html="Product.alterPriceHtml" v-if="Product.alterPrice"></span>
          <span v-html="Product.price_html" v-else-if="Product.price_html"></span>
        </div>
      </div>
      <div class="product-content">
        <!-- <div class="rating">
                      <star-rating style="display: flex; flex-direction: column" :rating="calcRate(Product.average_rating)"
                        :increment="0.01" :read-only="true" :star-size="18" :show-rating="false" v-bind:rtl="true" active-color="#fe6a00"
                        :glow="1" :animate="true"></star-rating>
                    </div> -->
        <div class="row">
          <div class="col-9">
            <router-link
              :to="{
                name: 'ProdOne',
                params: { Id: Product.id },
              }"
              class="see-details "
            >
              <span>{{ $t("ShowProductdetails") }}</span>
            </router-link>
          </div>
          <div class="col-3 align-self-center fav-col">
            <div class="row justify-content-start">
              <b-spinner
                v-if="isLoading"
                style="margin-right: 10px; padding-bottom:10px;"
                variant="secondary"
                label="  Spinning"
                class="mt-1"
              >
              </b-spinner>
              <button
                v-if="!isLoading"
                class="add-to-favorite"
                @click="updateFavorite(Product.id)"
              >
                <span v-if="isFavorite" title="حذف من المفضلة">
                  <i class="fas fa-heart fa-2x"></i>
                </span>
                <span v-else title="إضافة الى المفضلة">
                  <i class="far fa-heart fa-2x"></i>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import StarRating from "vue-star-rating";
// import VueLoadImage from "vue-load-image";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import axios from "axios";

export default {
  computed: {
    ...mapGetters(["Cart", "Token", "User"]),

    isLoggedIn() {
      if (!_.isEmpty(this.User) && !_.isEmpty(this.Token)) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    if (this.Product.images[0].src == "Empty") this.$parent.$el.innerHTML = "";
    if (this.Product) {
      if (this.Product.usersID.includes(this.User.ID)) this.isFavorite = true;
      else this.isFavorite = false;
    }
  },

  data() {
    return {
      isFavorite: false,
      isLoading: false,
    };
  },
  props: ["Product"],
  components: {
    // StarRating,
    // "vue-load-image": VueLoadImage,
  },

  methods: {
    ...mapActions(["AddToCartS"]),
    discountPrice(Product) {
      var stp1 = Product.regular_price - Product.sale_price;
      var stp2 = stp1 / Product.regular_price;
      var stp3 = stp2 * 100;
      return parseFloat(stp3).toFixed(0);
    },
    updateFavorite(Product) {
      this.$gtag.event("E_Favorite", { method: "Google" });
      if (!this.isLoggedIn) {
        this.$bvModal
          .msgBoxConfirm(
            " يجب عليك ان تقوم بإنشاء حساب لدينا كي تستطيع انشاء مفضلة خاصة بك .",
            {
              title: "عزيزي العميل",
              size: "md",
              buttonSize: "sm",
              bodyClass: "text-center my-2",
              okVariant: "primary",
              okTitle: "موافق",
              cancelTitle: "لاحقا",
              footerClass: "p-2 text-center",
              titleClass: " text-primary",
              hideHeaderClose: true,
              centered: true,
            }
          )
          .then((value) => {
            if (value) {
              this.$router.push({ name: "Register" });
            }
          });
      } else {
        var self = this;
        if (self.isFavorite) {
          self.isLoading = !self.isLoading;
          let setUrl =
            process.env.VUE_APP_DEVBASEURL +
            "/removeProductFavorite/" +
            Product +
            "/" +
            this.User.ID;
          axios.post(setUrl).then(function(res) {
            if (res.status == "200") {
              self.isFavorite = !self.isFavorite;
              self.isLoading = !self.isLoading;
              self.$emit("removeProd");
            }
          });
        } else {
          self.isLoading = !self.isLoading;
          let setUrl =
            process.env.VUE_APP_DEVBASEURL +
            "/addProductFavorite/" +
            Product +
            "/" +
            this.User.ID;
          axios.post(setUrl).then(function(res) {
            if (res.status == "200") {
              self.isFavorite = !self.isFavorite;
              self.isLoading = !self.isLoading;
            }
          });
        }
      }
    },
    calcRate(rate) {
      if (rate > 0) return parseFloat(rate);
      else {
        const str = (Math.random() * (4.5 - 3.9) + 3.9).toFixed(1);

        return parseFloat(str);
      }
    },
  },
};
</script>

<style lang="css" scoped>
.ProdOne {
  float: left;
  margin: 16px;
}

.product-grid {
  text-align: center;
  border-radius: 15px;
  border: 1px solid #e7e7e7;
  overflow: hidden;
  transition: all 0.4s ease-out;
  background: white;
  /* width: 70%; */
  height: auto;
}

.product-description {
  z-index: 888;
  position: absolute;
  background: #ffffff87;
  width: 100%;
  height: 100%;
  display: none;
}

.product-grid:hover {
  box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.1);
}

.product-grid .product-image {
  position: relative;
}

.product-grid .product-image a.image {
  display: block;
}

.product-grid .product-image img {
  height: auto;
}

.product-grid.descrition p {
  max-width: 20ch;
  white-space: break-spaces;
}

.product-grid .product-discount-label {
  color: #fe6a00;
  border: 1px #fe6a00 solid;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  padding: 2px 8px;
  position: absolute;
  top: 12px;
  left: 12px;
  max-width: 40%;
  max-height: 40%;
  padding: 5%;
  border-radius: 50%;
}

.product-grid .product-links {
  width: 145px;
  padding: 0;
  margin: 0;
  list-style: none;
  opacity: 0;
  transform: translateX(-50%) translateY(-50%);
  position: absolute;
  top: 65%;
  left: 50%;
  transition: all 0.4s ease 0s;
}

.product-grid:hover .product-links {
  opacity: 1;
  top: 50%;
}

.product-grid .product-links li {
  display: inline-block;
  margin: 0 2px;
}

.product-grid .product-links li a {
  color: #2c2c2c;
  background: #fff;
  font-size: 16px;
  line-height: 42px;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  display: block;
  position: relative;
  transition: all 0.4s ease-out 0s;
}

.product-grid .product-links li a:hover {
  color: #fff;
  background: #fe6a00;
  box-shadow: 1px 1px 30px 0 rgba(0, 0, 0, 0.2);
}

.product-grid .product-links li a:before,
.product-grid .product-links li a:after {
  content: attr(data-tip);
  color: #fff;
  background-color: #555;
  font-size: 12px;
  line-height: 18px;
  padding: 5px 10px;
  white-space: nowrap;
  display: none;
  transform: translateX(-50%);
  position: absolute;
  left: 50%;
  top: -40px;
  transition: all 0.3s ease 0s;
}

.product-grid .product-links li a:after {
  content: "";
  height: 10px;
  width: 10px;
  padding: 0;
  transform: translateX(-50%) rotate(45deg);
  top: -18px;
  z-index: -1;
}

.product-grid .product-links li a:hover:before,
.product-grid .product-links li a:hover:after {
  display: block;
}

.product-grid .product-content {
  padding: 0px 12px 2px;
  position: relative;
}

.product-grid .rating {
  padding: 0;
  margin: 15px 0 0px;
  list-style: none;
}

.product-grid .rating li {
  color: #fe6a00;
  font-size: 14px;
}

.product-grid .rating .far {
  color: #808080;
}

.product-grid .title {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0 0 15px;
  transition: all 0.4s ease-in-out;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product-grid .title a {
  color: #2c2c2c;
  transition: all 0.3s ease 0s;
  white-space: nowrap;
  max-width: 75ch;
}

.product-grid .product-data .title a:hover {
  color: #fe6a00;
}

.product-grid .price {
  color: #fe6a00;
  font-size: 14px;
  /* font-weight: 600; */
  display: block;
  transition: all 0.4s ease-in-out;
}

.price span {
  color: #fe6a00;
}

.product-content .see-details {
  color: #fe6a00;
  display: block;
  background-color: white;
  border: 1px #fe6a00 solid;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 30px;

  border-radius: 50px;
  padding: 2px;
  margin: 14px auto;
  transition: all 0.4s ease-out;
}

.add-to-favorite:hover {
  text-decoration: underline;
}

.product-content .add-to-favorite {
  color: #92278f;
  display: block;
  background-color: white;
  border: none;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 30px;
  padding-top: 5px !important;
  transition: all 0.4s ease-out;
}

.product-data {
  background-color: white;
  transition: all 0.4s ease-out;
  padding: 6px 0;
  padding-bottom: 0px !important;
  z-index: 666;
  position: relative;
}

.product-grid.descrition {
  display: none;
  position: absolute;
  background-color: white;
  text-align: center;
  white-space: break-spaces;
  transition: all 0.4s ease-out;
  width: 100% !important;
}

/*  Dsecription Animation When Hover On Img  */

.product-grid .product-image:hover + .product-grid.descrition {
  display: block;
  transition: all 0.4s ease-out;
}

.product-grid .product-image img {
  vertical-align: top;
  transition: opacity 0.3s;
  -webkit-transition: opacity 0.3s;
}

.product-grid .product-image:hover > a > img {
  opacity: 0.2;
}

.product-grid:hover + .product-image .product-description {
  display: block !important;
}

.rating li {
  display: inline-block;
}
.fav-col {
  padding-right: 0.3rem;
}
@media screen and (max-width: 1200px) {
  .product-grid {
    margin: 0 0 2px;
  }
}

@media screen and (max-width: 600px) {
  .fav-col {
  padding-right: 0.5rem;
}
}

.product-grid {
    min-height: 337px;
  }
</style>
