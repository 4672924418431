<template>
  <div class="accordion" role="tablist">
    <b-card no-body class="my-4 px-3 py-2"> 
      <div class="row justify-content-between">
        <h5>{{$t('ProdInf')}}</h5>
        <b-button variant="link" v-b-toggle.accordion-1><span class="fa fa-angle-down "></span></b-button>
      </div>
      <div class="row">
        <b-collapse id="accordion-1" class="mt-2" accordion="prod-one-accordion" role="tabpanel" visible  >
         <div class="text-start">
          <ul class="text-start" style="list-style:none"  >
            <li>
            <b>{{$t('Sku')}}:</b>
            {{ Product.sku }}
            </li>
            <li v-if="Product.size" >
            <b>{{$t('Size')}}:</b>
                {{Product.size}}
            </li>
            <li v-if="Product.weight">
            <b>{{$t('Weight')}}:</b>
                {{Product.weight}}
            </li>
            <li v-if="Product.thickness">
            <b>{{$t('Thickness')}}:</b>
                {{Product.thickness}}
            </li>
            <li v-if="Product.printing">
            <b>{{$t('Printing')}}:</b>
                {{Product.printing}}
            </li>
            <li v-if="Product.material">
            <b>{{$t('Material')}}:</b>
                {{Product.material}}
            </li>
            <li v-if="Product.color">
            <b>{{$t('Color')}}:</b>
                {{Product.color}}
            </li>
            <li v-if="Product.packing">
            <b>{{$t('Packing')}}:</b>
                {{Product.packing}}
            </li>
            <li v-if="Product.carton_qty">
            <b>{{$t('QuantityInBox')}}:</b>
                {{Product.carton_qty}}
            </li>
            <li v-if="Product.prepare_days">
            <b>{{$t('PrepareDay')}}:</b>
                {{Product.prepare_days}}
            </li>
          </ul>

         </div>
        </b-collapse>
      </div>
    </b-card>
    <!-- <b-card no-body class="my-4 px-3 py-2">
      <div class="row justify-content-between">
        <h5>{{$t('ProdDesc')}}</h5>
        <b-button variant="link" v-b-toggle.accordion-2><span class=" fa fa-angle-down "></span></b-button>
      </div>
      <div class="row">
        <b-collapse id="accordion-2" class="mt-2" accordion="prod-one-accordion" role="tabpanel" >
         <div class="text-start">
          <h5>{{Prod.name}} :</h5>
          <p v-html="Prod.short_description" ></p>
         </div>
        </b-collapse>
      </div>
    </b-card> -->

  
    <b-card no-body class="my-4 px-3 py-2">
      <div class="row justify-content-between">
        <h5>{{$t('Rating')}}</h5>
        <b-button variant="link" v-b-toggle.accordion-3><span class="fa fa-angle-down "></span></b-button>
      </div>
      <div class="row">
        <b-collapse id="accordion-3" class="mt-2 w-100" accordion="prod-one-accordion" role="tabpanel"  >
         <div class="text-start">
          
          <ProdRating v-bind:Rates="Prod.rates" :key="componentKey"  v-bind:Avg="parseFloat(Prod.avg_rate).toFixed(1)" v-bind:postId="Prod.id"  @renderRatingComponent="renderRating" ></ProdRating>
         </div>
        </b-collapse>
      </div>
    </b-card>


  </div>
</template>

<script>


import ProdRating from '../ProdOne/ProdRating.vue';
export default {

  props:['Prod','variation'],
  components:{
    ProdRating
  },
  mounted(){

    if(this.Prod.type === 'simple'){
      this.Product=this.Prod;
    }
    else if(this.Prod.type === 'variable'){
      this.Product=this.variation;
    }
  },
  data(){
    return {
      Product:{},
            componentKey:true

    }
  },  
  watch:{
    variation(newValue){
      if(newValue){
        this.$data.Product=newValue
      }

    }
  },
   methods:{
        renderRating(){
            this.componentKey=!this.componentKey
        }
    },

}
</script>

<style>

</style>