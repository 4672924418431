<template>
    <b-container fluid>
        <b-skeleton width="100%"></b-skeleton>
        <b-row>
        <b-col col cols='6' sm="2">
            <b-skeleton-img no-aspect height="200px" ></b-skeleton-img>
            <b-skeleton class="my-2 mx-auto" width="80%"></b-skeleton>
            <b-skeleton class="my-2 mx-auto" width="65%"></b-skeleton>
            <b-skeleton type="button" class="my-2 mx-auto" width="85%" ></b-skeleton>
        </b-col>
        <b-col col cols='6' sm="2">
            <b-skeleton-img no-aspect height="200px" ></b-skeleton-img>
            <b-skeleton class="my-2 mx-auto" width="80%"></b-skeleton>
            <b-skeleton class="my-2 mx-auto" width="65%"></b-skeleton>
            <b-skeleton type="button" class="my-2 mx-auto" width="85%" ></b-skeleton>
        </b-col>
        <b-col col cols='6' sm="2">
            <b-skeleton-img no-aspect height="200px" ></b-skeleton-img>
            <b-skeleton class="my-2 mx-auto" width="80%"></b-skeleton>
            <b-skeleton class="my-2 mx-auto" width="65%"></b-skeleton>
            <b-skeleton type="button" class="my-2 mx-auto" width="85%" ></b-skeleton>
        </b-col>
        <b-col col cols='6' sm="2">
            <b-skeleton-img no-aspect height="200px" ></b-skeleton-img>
            <b-skeleton class="my-2 mx-auto" width="80%"></b-skeleton>
            <b-skeleton class="my-2 mx-auto" width="65%"></b-skeleton>
            <b-skeleton type="button" class="my-2 mx-auto" width="85%" ></b-skeleton>
        </b-col>
        <b-col col cols='6' sm="2">
            <b-skeleton-img no-aspect height="200px" ></b-skeleton-img>
            <b-skeleton class="my-2 mx-auto" width="80%"></b-skeleton>
            <b-skeleton class="my-2 mx-auto" width="65%"></b-skeleton>
            <b-skeleton type="button" class="my-2 mx-auto" width="85%" ></b-skeleton>
        </b-col>
        <b-col col cols='6' sm="2">
            <b-skeleton-img no-aspect height="200px" ></b-skeleton-img>
            <b-skeleton class="my-2 mx-auto" width="80%"></b-skeleton>
            <b-skeleton class="my-2 mx-auto" width="65%"></b-skeleton>
            <b-skeleton type="button" class="my-2 mx-auto" width="85%" ></b-skeleton>
        </b-col>
        </b-row>

    </b-container>
</template>

<script>

export default {

    name:"ProdListLoader"

}

</script>


<style scoped>

</style>