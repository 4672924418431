<template>
    
    <b-container fluid>
        <div class="my-2">
          <b-skeleton-img no-aspect height="450px"></b-skeleton-img>
        </div>
    </b-container>
        
</template>

<script>
export default {

}
</script>

<style>

</style>