<template>
    <div class="row">
        <b-col sm="5" >
         <BannerLoader></BannerLoader>
        </b-col>
        <b-col sm="7">
            <b-skeleton class="my-2 " width="90%"></b-skeleton>
            <b-skeleton class="my-2 " width="90%"></b-skeleton>
            <b-skeleton class="my-2 " width="80%"></b-skeleton>
            <b-skeleton class="my-2 " width="90%"></b-skeleton>
            <b-skeleton class="my-2 " width="80%"></b-skeleton>
            <b-skeleton class="my-2 " width="80%"></b-skeleton>
            <b-skeleton class="my-2 " width="80%"></b-skeleton>
            <b-skeleton class="my-2 " width="80%"></b-skeleton>
            <b-skeleton class="my-2 " width="80%"></b-skeleton>
            <br>
            <b-skeleton type="button" class="my-2 " width="20%" ></b-skeleton>
        </b-col>
    </div>

</template>


<script>

import BannerLoader from '../BannerLoader.vue';

export default {
    components:{
       
        BannerLoader
    }
}
</script>

<style>

</style>