<template>
  <div class="row">
    <div class="col-sm-3">
      <div class="d-flex flex-column">
        <div class="rating-content mx-auto" v-if="Avg > 0">
          <p
            class="text-center font-weight-bold"
            style="color: #fe6a00; font-size: 54px"
          >
            {{ Avg }}
          </p>
          <star-rating
            :rating="parseFloat(Avg)"
            :increment="0.01"
            :read-only="true"
            :star-size="38"
            :show-rating="false"
            v-bind:rtl="true"
            active-color="#fe6a00"
            :glow="1"
            :animate="true"
          ></star-rating>
        </div>
        <b-button class="mx-auto mt-3" v-b-modal.modal-rate variant="primary">{{
          $t("AddNewReview")
        }}</b-button>
      </div>
    </div>
    <div class="col-sm-9">
      <Flickity
        ref="flickity"
        :options="flickityOptions"
        v-if="approvedRates.length != 0"
      >
        <div
          v-for="rate in approvedRates"
          v-bind:key="rate.id"
          class="w-100 text-center"
        >
          <b-card v-if="rate.status == 'approved'">
            <star-rating
              :rating="parseFloat(rate.value)"
              :increment="0.01"
              :read-only="true"
              :star-size="18"
              :show-rating="false"
              active-color="#fe6a00"
              :glow="1"
              :animate="true"
              style="display: inline-block"
              class="mb-3 text-center"
            ></star-rating>
            <h5 class="mt-0 mb-1">{{ rate.name }}</h5>
            <p class="font-weight-light">
              {{ new Date(rate.created_at).toLocaleDateString() }}
            </p>
            <p class="mb-0">
              {{ rate.desc }}
            </p>
          </b-card>
        </div></Flickity
      >
    </div>
    <div class="d-flex justify-content-between my-3 px-1">
      <!-- <select class="form-control w-50">
                <option>أضيفت مؤخرا</option>
            </select> -->

      <b-modal id="modal-rate" centered>
        <div class="RateForm mx-4 my-3">
          <b-form>
            <b-form-group
              id="name-live-feedback"
              label-for="name-live-feedback"
            >
              <b-form-input
                id="name-live-feedback"
                name="name-live-feedback"
                v-model="$v.form.name.$model"
                :state="validateState('name')"
                aria-describedby="name-live-feedback"
                :placeholder="$t('FullName')"
              ></b-form-input>
            </b-form-group>

            <b-form-group class="d-flex justify-content-center my-4">
              <star-rating
                v-model="form.rate"
                :read-only="false"
                :star-size="42"
                :show-rating="false"
                v-bind:rtl="true"
                active-color="#fe6a00"
                :glow="1"
                :animate="true"
              ></star-rating>
            </b-form-group>
            <b-form-group
              id="desc-live-feedback"
              label-for="desc-live-feedback"
            >
              <b-form-textarea
                id="textarea"
                :placeholder="$t('TellUsMore')"
                rows="5"
                max-rows="6"
                :state="validateState('desc')"
                v-model="$v.form.desc.$model"
                aria-describedby="desc-live-feedback"
              ></b-form-textarea>
            </b-form-group>
          </b-form>
        </div>
        <template #modal-footer>
          <b-button
            type="button"
            @click="$bvModal.hide('modal-rate')"
            variant="secondary"
            >{{ $t("Cancel") }}</b-button
          >
          <b-button type="submit" variant="primary" @click="onRateSubmit">{{
            $t("send")
          }}</b-button>
        </template>
      </b-modal>
      <b-modal
        ok-only
        id="modal-thank"
        centered
        title=""
        footerClass="mx-auto"
        okTitle="حسناً"
        size="md"
      >
        <template #modal-header>
          <h3 class="mx-auto text-primary">شكراً لكم!</h3>
        </template>
        <div class="mx-auto text-center mb-2 mt-2">
          <p class="mx-auto text-primary">
            شكراً لك على تقييمك عميلنا العزيز 😊، نقدر ذلك حقاً
          </p>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import StarRating from "vue-star-rating";
import axios from "axios";
import Flickity from "vue-flickity";
import { mapActions } from "vuex";

export default {
  props: ["Rates", "Count", "Avg", "postId"],
  components: {
    StarRating,
    Flickity,
  },
  data() {
    return {
      flickityOptions: {
        pageDots: false,
        wrapAround: true,
        freeScroll: true,
        adaptiveHeight: true,
      },
      form: {
        name: null,
        rate: 4,
        desc: null,
      },
      DistruProps: [],
    };
  },
  validations: {
    form: {
      rate: {
        required,
      },
      name: {
        required,
        minLength: minLength(3),
      },
      desc: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(150),
      },
    },
  },
  methods: {
    ...mapActions(["getProdOne"]),

    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },

    onRateSubmit() {
      this.$v.$touch();
      var self = this;
      if (!this.$v.$invalid) {
        var url = process.env.VUE_APP_DEVBASEURL + "/saveRateProd";
        var data = {
          RateNameI: this.form.name,
          RateDescI: this.form.desc,
          RateValI: this.form.rate,
          PostId: this.postId,
        };
        axios.post(url, data).then(function() {
          //Refresh The Page
          self.form.name = "";
          self.form.desc = "";
          self.form.rate = "";

          self.$v.$reset();
          // var obj = {
          //   id: self.$route.params.Id,
          //   name: self.$route.params.ProdName,
          // };
          // return self.getProdOne(obj);
        });
        this.$nextTick(() => {
          this.$bvModal.hide("modal-rate");
          setTimeout(() => {
            this.$bvModal.show("modal-thank");
          }, 2000);
        });
      }
    },
  },
  watch: {
    Rates() {
      this.$emit("renderRatingComponent");
    },
  },
  computed: {
    
    approvedRates() {
      return this.Rates.filter((rate) => {
        console.log(rate);
        return rate.status == "approved";
      });
    },
  },
  mounted() {},
};
</script>

<style scoped>
.RateProgress {
  flex: 1;
}
</style>
