<template>
  <b-skeleton-wrapper :loading="OneLoading">
    <template #loading>
      <b-container fluid class="my-4">
        <OneLoader></OneLoader>
        <ProdListLoader></ProdListLoader>
      </b-container>
    </template>
    <div class="ProdOne container-fluid py-3 py-sm-5">
      <div class="row">
        <!-- Prod Inf  -->
        <div class="col-sm-6 mb-5">
          <h5
            class="ProdTitle mb-3 text-start font-weight-bold"
            v-if="$mq === 'sm'"
          >
            {{ ProdOne.name }}
          </h5>
          <ImgScrollerProd v-bind:gallery="this.gallery"  :name="ProdOne.name"/>
        </div>
        <div class="col-sm-6" style="text-align: start">
          <div class="ProdInf">
            <h3 class="ProdTitle" v-if="$mq === 'md' || $mq === 'lg'">
              {{ ProdOne.name }}
            </h3>
          </div>
          <!-- <div class="starRate">
            <star-rating
              :rating="parseInt(ProdOne.avg_rate)"
              :increment="0.01"
              :read-only="true"
              :star-size="18"
              :show-rating="false"
              v-bind:rtl="true"
              active-color="#fe6a00"
              :glow="1"
              :animate="true"
            ></star-rating>
          </div> -->
          <!-- bug here -->
          <p v-html="ProdOne.short_description"></p>

          <div v-if="!ProdOne.variations" class="Price">
              <span v-html="Product.alterPriceHtml" v-if="Product.alterPrice"></span>
          <span v-html="Product.price_html" v-else-if="Product.price_html"></span>
            
            <h6 class="text-dark" style="font-size: 14px">
              السعر قابل للتفاوض
            </h6>
          </div>
          <p v-if="ProdOne.variations">اختر المقاس</p>

          <div class="justify-content-center">
            <b-button
              v-for="variation in ProdOne.variations"
              v-bind:key="variation.ID"
              variant="outline-primary"
              class="m-1"
              :class="isActive == variation.ID ? 'active':''"
              @click="onVariationClick(variation)"
            >
              {{ variation.post_excerpt }}
            </b-button>
          </div>
          <div v-if="ProdOne.variations && variationAppear" class="Price">
            <span v-html="variationPrice"></span>
            <h6 class="text-dark" style="font-size: 14px">
              السعر قابل للتفاوض
            </h6>
          </div>
          <div class="my-3">
            <div class="d-flex text-secondary">
              <a
                v-if="ProdOne.type === 'simple'"
                target="_blank"
                :href="
                  'https://wa.me/971565628959?text= ' +
                    ProdOne.sku +
                    ' مرحباً، أريد الاستفسار عن المنتج '
                "
                class="WhaBtn"
                @click="googleTag()"
                ><i class="fab fa-whatsapp text-secondary"></i>
                <p
                  style="font-size: 18px; margin: 0 8px; font-weight: 600"
                  class="text-secondary"
                >
                  اطلب الان
                </p>
              </a>
              <a
                v-if="
                  ProdOne.type === 'variable' &&
                    variationItem.sku &&
                    variationAppear
                "
                target="_blank"
                :href="
                  'https://wa.me/971565628959?text= ' +
                    variationItem.sku +
                    'مرحباً، أريد الاستفسار عن المنتج'
                "
                class="WhaBtn"
                @click="googleTag()"
                ><i class="fab fa-whatsapp text-secondary"></i>
                <p
                  style="font-size: 18px; margin: 0 8px; font-weight: 600"
                  class="text-secondary"
                >
                  اطلب الان
                </p>
              </a>
              <span
                v-if="
                  (ProdOne.type === 'variable' &&
                    variationItem.sku &&
                    variationAppear) ||
                    ProdOne.type === 'simple'
                "
                class="my-auto"
              >
                <button
                  class=" bg-white border-0 text-secondary"
                  @click="updateFavorite(ProdOne.id)"
                  style="font-size: 18px; margin: 0 8px; font-weight: 600"
                >
                  <span v-if="isFavorite" title="حذف من المفضلة">
                    <i class="fas fa-heart fa-2x"></i>
                  </span>
                  <span v-else title="إضافة الى المفضلة">
                    <i class="far fa-heart fa-2x"></i>
                  </span>
                </button>
              </span>
            </div>
          </div>
          <div v-if="$mq === 'md' || $mq === 'lg'">
            <div
              v-if="
                ProdOne.type === 'variable' &&
                  variationItem.sku &&
                  variationAppear
              "
            >
              <b class="ProdTitle text-primary"> {{ $t("ProdInf") }}</b>
              <ul class="mt-1 px-0" style="list-style: none">
                <li>
                  <b>{{ $t("Sku") }}:</b>
                  {{ Product.sku }}
                </li>
                <li v-if="Product.size">
                  <b>{{ $t("Size") }}:</b>
                  {{ Product.size }}
                </li>
                <li v-if="Product.weight">
                  <b>{{ $t("Weight") }}:</b>
                  {{ Product.weight }}
                </li>
                <li v-if="Product.thickness">
                  <b>{{ $t("Thickness") }}:</b>
                  {{ Product.thickness }}
                </li>
                <li v-if="Product.printing">
                  <b>{{ $t("Printing") }}:</b>
                  {{ Product.printing }}
                </li>
                <li v-if="Product.material">
                  <b>{{ $t("Material") }}:</b>
                  {{ Product.material }}
                </li>
                <li v-if="Product.color">
                  <b>{{ $t("Color") }}:</b>
                  {{ Product.color }}
                </li>
                <li v-if="Product.packing">
                  <b>{{ $t("Packing") }}:</b>
                  {{ Product.packing }}
                </li>
                <li v-if="Product.cartqty">
                  <b>{{ $t("QuantityInBox") }}:</b>
                  {{ Product.cartqty }} {{ unit }}
                </li>
                <li v-if="Product.prepare_days">
                  <b>{{ $t("PrepareDay") }}:</b>
                  {{ Product.prepare_days }}
                </li>
              </ul>
            </div>
            <div v-if="ProdOne.type === 'simple'">
              <b class="ProdTitle text-primary"> {{ $t("ProdInf") }}</b>
              <ul class="mt-1 px-0" style="list-style: none">
                <li>
                  <b>{{ $t("Sku") }}:</b>
                  {{ Product.sku }}
                </li>
                <li v-if="Product.size">
                  <b>{{ $t("Size") }}:</b>
                  {{ Product.size }}
                </li>
                <li v-if="Product.weight">
                  <b>{{ $t("Weight") }}:</b>
                  {{ Product.weight }}
                </li>
                <li v-if="Product.thickness">
                  <b>{{ $t("Thickness") }}:</b>
                  {{ Product.thickness }}
                </li>
                <li v-if="Product.printing">
                  <b>{{ $t("Printing") }}:</b>
                  {{ Product.printing }}
                </li>
                <li v-if="Product.material">
                  <b>{{ $t("Material") }}:</b>
                  {{ Product.material }}
                </li>
                <li v-if="Product.color">
                  <b>{{ $t("Color") }}:</b>
                  {{ Product.color }}
                </li>
                <li v-if="Product.packing">
                  <b>{{ $t("Packing") }}:</b>
                  {{ Product.packing }}
                </li>
                <li v-if="Product.carton_qty">
                  <b>{{ $t("QuantityInBox") }}:</b>
                  {{ Product.carton_qty }} {{ unit }}
                </li>
                <li v-if="Product.prepare_days">
                  <b>{{ $t("PrepareDay") }}:</b>
                  {{ Product.prepare_days }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- Prod Inf End  -->
      </div>

      <b-row>
        <b-col cols="12" sm="12">
          <OneNav
            v-if="$mq === 'md' || $mq === 'lg'"
            v-bind:Prod="ProdOne"
            v-bind:variation="variationItem"
          ></OneNav>
          <OneCollapse
            v-if="$mq === 'sm'"
            v-bind:Prod="ProdOne"
            v-bind:variation="variationItem"
          ></OneCollapse>
        </b-col>
      </b-row>

      <div
        v-if="!OneLoading"
        style="background-color: #f5f5f5ec !important"
        class="py-5"
      >
        <div class="TitleList d-flex justify-content-between">
          <h3 class="text-right">{{ $t(ProdOneSimilarLayout.name) }}</h3>
          <div class="">
            <b-button
              variant="outline-secondary"
              class="py-1 font-weight-bold"
              :style="[$mq == 'sm' ? 'font-size: 12px;' : 'font-size: 15px;']"
              :to="{
                name: 'ProdByCat',
                params: { ProdByCat: ProdOneSimilarLayout.value },
              }"
              >{{ $t("ShowMore") }}</b-button
            >
          </div>
        </div>
        <div class="ProdList">
          <Flickity
            ref="flickity"
            :options="flickityOptions"
            v-if="ProdOneSimilarLayout.length != 0"
          >
            <ProdCard
              class="col-sm-2 px-1 carousel-cell col-8"
              v-for="index in 10"
              v-bind:key="index"
              v-bind:Product="ProdOneSimilarLayout[index - 1]"
            ></ProdCard>
          </Flickity>
        </div>
      </div>
    </div>
  </b-skeleton-wrapper>
</template>

<script>
import ImgScrollerProd from "../components/widgets/ImgScrollerProd.vue";
// import StarRating from "vue-star-rating";
import OneCollapse from "../components/widgets/ProdOne/OneCollapse.vue";
import OneNav from "../components/widgets/ProdOne/OneNav.vue";
import OneLoader from "../components/widgets/ProdOne/OneLoader.vue";
import { mapActions, mapGetters } from "vuex";
import ProdListLoader from "../components/widgets/PordListLoader.vue";
import ProdCard from "../components/items/ProdCard.vue";
import Flickity from "vue-flickity";
import _ from "lodash";
import axios from "axios";

// import VueLoadImage from 'vue-load-image'

export default {
  components: {
    ImgScrollerProd,
    // StarRating,
    OneCollapse,
    OneNav,
    OneLoader,
    ProdListLoader,
    ProdCard,
    Flickity,
    //VueLoadImage,
  },
  computed: {
    ...mapGetters(["ProdOne", "ProdOneSimilarLayout", "User", "Token"]),

    isLoggedIn() {
      if (!_.isEmpty(this.User) && !_.isEmpty(this.Token)) {
        return true;
      } else {
        return false;
      }
    },
    isLtr() {
      return this.$i18n.locale === "en";
    },
    Product() {
      if (this.ProdOne.type === "simple") {
        return this.ProdOne;
      } else {
        return this.variationItem;
      }
    },
    unit() {
      if (this.ProdOne.type === "simple") {
        var al_price_for = this.ProdOne.meta.find((meta) => {
          return meta.meta_key == "al_price_for";
        }).meta_value;
        if (al_price_for) return al_price_for;
        else return "";
      } else {
        var al_unit = this.ProdOne.variations[0].meta.find((meta) => {
          return meta.meta_key == "al_unit";
        }).meta_value;
        if (al_unit) return al_unit;
        else return "";
      }
    },
  },
  data() {
    return {
      isActive: 0,
      isFavorite: false,
      isLoading: false,
      variationPrice: "",
      variationAppear: false,
      displayImg: false,
      DistruProps: [],
      OneLoading: true,
      gallery: [],
      variationItem: {},
      flickityOptions: {
        initialIndex: 3,
        pageDots: false,
        freeScroll: false,
        wrapAround: false,
        contain: true,
        imagesLoaded: true,
      },
    };
  },
  metaInfo: {
    title: "Product",
  },
  methods: {
    ...mapActions(["getProdOne"]),
    updateFavorite(Product) {
      this.$gtag.event("E_Favorite", { method: "Google" });
      if (!this.isLoggedIn) {
        this.$bvModal
          .msgBoxConfirm(
            " يجب عليك ان تقوم بإنشاء حساب لدينا كي تستطيع انشاء مفضلة خاصة بك .",
            {
              title: "عزيزي العميل",
              size: "md",
              buttonSize: "sm",
              bodyClass: "text-center my-2",
              okVariant: "primary",
              okTitle: "موافق",
              cancelTitle: "لاحقا",
              footerClass: "p-2 text-center",
              titleClass: " text-primary",
              hideHeaderClose: true,
              centered: true,
            }
          )
          .then((value) => {
            if (value) {
              this.$router.push({ name: "Register" });
            }
          });
      } else {
        var self = this;
        if (self.isFavorite) {
          self.isLoading = !self.isLoading;
          let setUrl =
            process.env.VUE_APP_DEVBASEURL +
            "/removeProductFavorite/" +
            Product +
            "/" +
            this.User.ID;
          axios.post(setUrl).then(function(res) {
            if (res.status == "200") {
              self.isFavorite = !self.isFavorite;
              self.isLoading = !self.isLoading;
              self.$emit("removeProd");
            }
          });
        } else {
          self.isLoading = !self.isLoading;
          let setUrl =
            process.env.VUE_APP_DEVBASEURL +
            "/addProductFavorite/" +
            Product +
            "/" +
            this.User.ID;
          axios.post(setUrl).then(function(res) {
            if (res.status == "200") {
              self.isFavorite = !self.isFavorite;
              self.isLoading = !self.isLoading;
            }
          });
        }
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onVariationClick(item) {
      this.isActive=item.ID
      this.variationAppear = true;
      this.$data.variationItem = item;
      this.variationPrice = item.finalPrice.alterPrice?item.finalPrice.alterPriceHtml:item.finalPrice.price_html;
    },
    googleTag() {
      this.$gtag.event("E_OrderNow", { method: "Google" });
      this.$gtag.event("E_WhatsAPP", { method: "Google" });
    },
  },
  watch: {
    ProdOne(newValue) {
      if (newValue) {
        this.OneLoading = false;
        this.displayImg = true;
        this.gallery = newValue.gallery;
      }
      window.scrollTo(0, 0);
    },

    "$route.params.Id"() {
      this.OneLoading = true;
      this.variationAppear = false;

      var obj = {
        id: this.$route.params.Id,
      };
      return this.getProdOne(obj);
    },
    variationItem(newValue) {
      if (newValue) {
        this.$data.Product = newValue;
      }
    },
  },
  mounted() {
    var prodID = this.$route.params.Id;
    var message = "Product One ID " + prodID + " Page";
    this.$gtag.event(message, { method: "Google" });
    var obj = {
      id: this.$route.params.Id,
      name: this.$route.params.ProdName,
    };

    this.getProdOne(obj);
    window.scrollTo(0, 0);
    this.variationAppear = false;
    this.variationPrice = "";
  },
};
</script>

<style scoped>
.starRate {
  margin: 20px 0;
}

.qtyCounter {
  border: 1px rgba(0, 0, 0, 0.336) solid;
  border-radius: 6px;
}

.qtyCounter button {
  background: none;
  border: none;
  padding: 6px;
}

.qtyCounter span {
  padding: 6px;
  margin: auto;
}

/* .Price{
      font-weight: bolder;
      font-size: 26px;
      color: #fe6a00;
} */

.ProdOne {
  background: white;
  margin-top: 10px;
}

.Price {
  font-weight: bolder;
  font-size: 32px;
  color: #fe6a00;
}

.regPrice {
  color: #9f9f9f;
}

.regPriceWithSale::before {
  content: "";
  width: 100%;
  position: absolute;
  right: 0;
  top: calc(50% - 1.5px);
  border-bottom: 3px solid rgba(255, 0, 0, 0.8);
}

.tab-pane {
  margin: 16px;
}

.Qty {
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  margin: 12px 0;
}

.Qty p {
  margin: 0 8px;
  font-size: 10px;
  font-weight: bold;
}

.Qty a {
  border: 1px #fe6a00 solid;
  padding: 8px;
  margin: 8px;
  border-radius: 24px;
  text-decoration: none;
}

.Qty input[type="number"]::-webkit-inner-spin-button,
.Qty input[type="number"]::-webkit-outer-spin-button {
  opacity: 1;
}

.vue-star-rating .sr-only {
  left: unset !important;
}

.ProdMetaList {
  padding: 0;
}

.RateOneImg {
  margin: 0 16px;
}

.RateProgress {
  width: 100%;
}

.RateProgress .progress-br {
  margin: 6px 0;
}

.RateForm {
  margin: 16px 0;
}

.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}

.when-opened,
.when-closed {
  position: absolute;
  left: 10px;
}

.PropItem input {
  display: none;
}

.PropItem label {
  background: #fff;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 5px;
  cursor: pointer;
  border: 2px solid lightgrey;
  transition: all 0.3s ease;
}

.WhaBtn {
  display: flex;
  border: 2px #92278f solid;
  border-radius: 25px;
  align-items: center;
  justify-content: center;
  padding: 10px;
  font-size: 30px;
  font-weight: bold;
  margin: 2px 10px;
  width: auto;
  transition-property: font-size;
  transition-duration: 0.1s;
  transition-timing-function: linear;
}

.WhaBtn:hover {
  text-decoration: none;
}

.arrowGif {
  transform: scaleX(-1);
}

@keyframes glowing {
  0% {
    text: #92278f !important;
    box-shadow: 0 0 5px #92278f;
  }

  50% {
    text: #bb32b6 !important;
    box-shadow: 0 0 20px #bb32b6;
  }

  100% {
    text: #e23ddd !important;
    box-shadow: 0 0 5px #e23ddd;
  }
}

.WhaBtn {
  animation: glowing 1300ms infinite;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .Qty {
    flex-direction: column !important;
    margin-bottom: 20px;
  }

  .WhaBtn {
    font-size: 18px;
  }
}
</style>
