<template>
  <b-tabs content-class="mt-3" class="my-5" fill active>
    <!-- <b-tab :title="$t('ProdDesc')" >
        <div class="text-start">
         <h4>{{Prod.name}} :</h4>
         <p v-html="Prod.short_description"> </p>
        </div>
    </b-tab> -->
    <!-- <b-tab :title="$t('ProdInf')"  >
          <ul class="text-start" style="list-style:none"  >
            <li>
            <b>{{$t('Sku')}}:</b>
            {{ Product.sku }}
            </li>
            <li v-if="Product.size" >
            <b>{{$t('Size')}}:</b>
                {{Product.size}}
            </li>
            <li v-if="Product.weight">
            <b>{{$t('Weight')}}:</b>
                {{Product.weight}}
            </li>
            <li v-if="Product.thickness">
            <b>{{$t('Thickness')}}:</b>
                {{Product.thickness}}
            </li>
            <li v-if="Product.printing">
            <b>{{$t('Printing')}}:</b>
                {{Product.printing}}
            </li>
            <li v-if="Product.material">
            <b>{{$t('Material')}}:</b>
                {{Product.material}}
            </li>
            <li v-if="Product.color">
            <b>{{$t('Color')}}:</b>
                {{Product.color}}
            </li>
            <li v-if="Product.packing">
            <b>{{$t('Packing')}}:</b>
                {{Product.packing}}
            </li>
            <li v-if="Product.carton_qty">
            <b>{{$t('QuantityInBox')}}:</b>
                {{Product.carton_qty}}
            </li>
            <li v-if="Product.prepare_days">
            <b>{{$t('PrepareDay')}}:</b>
                {{Product.prepare_days}}
            </li>
          </ul>
    </b-tab>   -->

    <b-tab :title="$t('Rating') + ''" @click="renderRating()">
      <ProdRating
        v-bind:Rates="Prod.rates"
        v-bind:Avg="parseFloat(Prod.avg_rate).toFixed(1)"
        v-bind:postId="Prod.id"
        :key="componentKey"
        @renderRatingComponent="renderRating" 
      ></ProdRating>
    </b-tab>
    <!-- <b-tab :title="$t('a&q')">

    </b-tab> -->
  </b-tabs>
</template>

<script>
import ProdRating from "../ProdOne/ProdRating.vue";

export default {
  props: ["Prod", "variation"],
  components: {
    ProdRating,
  },
  data() {
    return {
      Product: {},
      componentKey: true,
    };
  },
  watch: {
    variation(newValue) {
      if (newValue) {
        this.$data.Product = newValue;
      }
    },
  },
  mounted() {
    if (this.Prod.type === "simple") {
      this.Product = this.Prod;
    } else if (this.Prod.type === "variable") {
      this.Product = this.variation;
    }
  setTimeout(() => {
    this.componentKey = !this.componentKey;
    
  }, 200);
  },
  methods: {
    renderRating() {
      this.componentKey = !this.componentKey;
    },
  },
};
</script>

<style scoped></style>
